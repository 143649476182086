import { initializeApp } from '@firebase/app';
import { getMessaging, onMessage, getToken } from '@firebase/messaging';
import { firebaseConfig, vapidKey } from './config';

console.log("Run v3");
const fbApp = initializeApp(firebaseConfig);

console.log('initializeApp', fbApp);

const messaging = getMessaging(fbApp);
console.log('getMessaging', messaging);

// Get registration token. Initially this makes a network call, once retrieved
// subsequent calls to getToken will return from cache.
getToken(messaging, { vapidKey: vapidKey }).then((currentToken) => {
  if (currentToken) {
    // Send the token to your server and update the UI if necessary
    console.log("currentToken", currentToken);
  } else {
    // Show permission request UI
    console.log('No registration token available. Request permission to generate one.');
  }
}).catch((err) => {
  console.error('An error occurred while retrieving token. ', err);
});


onMessage(messaging, (payload) => {
  console.log('Message received. ', payload);
  // ...
});

console.log('INIT');
//
// if ('serviceWorker' in navigator) {
//
//   console.log("Adding serviceWorker...");
//
//   window.addEventListener('load', () => {
//
//     navigator.serviceWorker.register('/service-firebase-messaging-sw.js')
//       .then((registration) => {
//         // Registration was successful
//         console.log('ServiceWorker registration successful with scope: ', registration.scope);
//       })
//       .catch(err => {
//         // registration failed :(
//         console.log('ServiceWorker registration failed: ', err);
//       });
//
//   });
//
// } else {
//   console.log("NO serviceWorker!!!");
// }

